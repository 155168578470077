import {opacify} from 'color2k';
import {Components, Theme} from '@mui/material/styles';
import {gridClasses} from '@mui/x-data-grid-pro';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import {circularProgressClasses} from '@mui/material';

export const dataGrid = (
  theme: Theme
): {
  MuiDataGrid: Components['MuiDataGrid'];
} => ({
  MuiDataGrid: {
    defaultProps: {
      columnHeaderHeight: 56,
      rowHeight: 52,
      hideFooter: true,
      disableColumnMenu: true,
      disableColumnReorder: true,
    },
    styleOverrides: {
      root: {
        borderColor: theme.palette.divider,
        '--unstable_DataGrid-radius': `${theme.shape.borderRadiusLarge}px`,
        '--DataGrid-rowBorderColor': theme.palette.divider,
        '--DataGrid-containerBackground': opacify(theme.palette.background.grey, 0.6),
        '--DataGrid-pinnedBackground': theme.palette.background.paper,

        [`& :is(.${gridClasses['container--top']}, .${gridClasses['container--bottom']}) .${gridClasses.row}`]: {
          backgroundColor: theme.palette.common.white,
        },
      },
      cell: {
        ...theme.typography.cellText,
        display: 'flex',
        alignItems: 'center',
        paddingInline: theme.spacing(4),

        ['&:focus, &:focus-within']: {
          outline: 'none',
        },
      },
      columnHeader: {
        ['&:focus, &:focus-within']: {
          outline: 'none',
        },

        [`&:not(.${gridClasses.columnHeaderCheckbox})`]: {
          padding: theme.spacing(0, 4),
        },

        [`&:is(.${gridClasses['columnHeader--pinnedLeft']}, .${gridClasses['columnHeader--pinnedRight']})`]: {
          background: 'var(--DataGrid-containerBackground)',
        },
      },
      ['columnHeader--alignRight']: {
        [`& :is(.${gridClasses.columnHeaderDraggableContainer}, .${gridClasses.columnHeaderTitleContainer})`]: {
          flexDirection: 'row',
          justifyContent: 'flex-end',
        },
      },
      columnHeaderTitle: {
        ...theme.typography.cellHeader,
        color: theme.palette.text.primary,
        textTransform: 'capitalize',

        '& > button': {
          color: theme.palette.text.primary,
        },
      },
      iconButtonContainer: {
        visibility: 'visible',
        width: 'auto',
      },
      overlay: {
        [`& .${circularProgressClasses.root}`]: {
          color: theme.palette.common.black,
        },
      },
    },
  },
});
