import {Helmet} from 'react-helmet';

export function DataDog() {
  return (
    <Helmet>
      <script type='text/javascript' src='https://www.datadoghq-browser-agent.com/datadog-logs-us.js' />

      <script type='text/javascript'>
        {`
          window.DD_LOGS && window.DD_LOGS.init({
              clientToken: 'pub16ec7c00ff5462de434527a12a5f68f2',
              site: 'datadoghq.com',
              forwardErrorsToLogs: true,
              sessionSampleRate: 100
          });
        `}
      </script>
    </Helmet>
  );
}
