import {Slide, styled, Typography} from '@mui/material';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

import {useUserInfo} from 'core/hooks/authHooks';
import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {useProjectName} from 'utils/hooks/useProjectName';
import {shouldNotForwardProp} from 'utils/styled';

import HelpIcon from 'components/icons/help.svg';

import {setRevealLaunchedKey, useRevealPluginStore} from './store';

const COMMAND_SITE_KEY = 'rev-site-.3VSSBNY-TVSEH2A-S6IKSII-K2VELXI';
const DECISIONS_SITE_KEY = 'rev-site-.DRPB2QI-J7IEEZI-SA7LOVA-5CBE4JI';
const REVEAL_API_KEY = 'rev-.LUV63BA-5G2UYYQ-ROZ5PRQ-ZB6MIOI';
const LOADED_EVENT = 'revealCopilotLoaded';

const SCopilotTrigger = styled(
  'div',
  shouldNotForwardProp('isCommand')
)<{isCommand: boolean}>(({theme, isCommand}) => ({
  position: 'fixed',
  bottom: 110,
  right: 0,
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  color: theme.palette.common.white,
  backgroundColor: isCommand ? '#08A8A8' : '#7D3DFF',
  borderBottomLeftRadius: 16,
  borderTopLeftRadius: 16,
  cursor: 'pointer',
}));

/**
 * RevealPlugin
 * https://academy.reveal.pub/knowledge-bases/cloo8t9y90000jv0frb93wxo8/pages/cltxv211w0002qav5scnnydii
 * @constructor
 */
export function RevealPlugin() {
  const isDownSm = useIsDownSm();
  const {isCommand} = useProjectName();
  const userInfo = useUserInfo();
  const launchedRevealSitKey = useRevealPluginStore(store => store.launchedRevealSitKey);

  const siteKey = isCommand ? COMMAND_SITE_KEY : DECISIONS_SITE_KEY;
  const isInitialized = launchedRevealSitKey === siteKey;

  useEffect(() => {
    setTimeout(() => {
      try {
        if (launchedRevealSitKey !== siteKey) {
          window?.reveal?.launchCopilot(REVEAL_API_KEY, siteKey);
        }
      } catch (e) {
        console.error(e);
      }
    });

    const handleEvent = () => {
      setRevealLaunchedKey(siteKey);

      window?.reveal?.identify({
        userId: userInfo?.id,
        name: userInfo?.profile?.name,
        email: userInfo?.profile?.email,
        role: userInfo?.roleType,
        company: {
          id: userInfo?.tenantId,
          name: userInfo?.tenantName,
        },
      });
    };

    document.addEventListener(LOADED_EVENT, handleEvent);

    return () => {
      document.removeEventListener(LOADED_EVENT, handleEvent);
    };
  }, [launchedRevealSitKey, siteKey, userInfo]);

  return (
    <>
      <Helmet>
        <link href='https://reveal-plugin.s3.amazonaws.com/style.css' rel='stylesheet' />

        <script
          type='module'
          src='https://reveal-plugin.s3.amazonaws.com/index.js'
          id='revealScript'
          data-api-key='rev-.LUV63BA-5G2UYYQ-ROZ5PRQ-ZB6MIOI'
          data-site-key={siteKey}
        />
      </Helmet>

      <Slide direction='left' in={isInitialized}>
        <SCopilotTrigger isCommand={isCommand} onClick={() => window?.reveal?.openCopilotWindow()}>
          {isDownSm ? <HelpIcon width={20} height={20} /> : <Typography variant='body2'>Need Help?</Typography>}
        </SCopilotTrigger>
      </Slide>
    </>
  );
}
