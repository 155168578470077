import {createWithEqualityFn} from 'zustand/traditional';
import {devtools} from 'zustand/middleware';
import {shallow} from 'zustand/shallow';

import {IS_DEV_ENV} from 'config';

export type RevealState = {
  launchedRevealSitKey: string | null;
};

export const useRevealPluginStore = createWithEqualityFn<RevealState>()(
  devtools<RevealState>(
    () => ({
      launchedRevealSitKey: null,
    }),
    {
      enabled: IS_DEV_ENV,
      name: 'Reveal Pligin',
      store: 'reveal_plugin_store',
    }
  ),
  shallow
);

export const setRevealLaunchedKey = (launchedSiteKey: string) =>
  useRevealPluginStore.setState(
    state => ({
      ...state,
      launchedRevealSitKey: launchedSiteKey,
    }),
    false,
    'launchedRevealSitKey'
  );
